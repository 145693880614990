import { Box, List, ListItem, ListItemText, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

export const SentimentsList = ({ type, sentimentsArray }) => {
  const [color, setColor] = useState("");

  useEffect(() => {
    if (type === "Positive Sentiments") {
      setColor("rgba(46, 255, 46, 0.05)");
    }
    if (type === "Negative Sentiments") {
        setColor("rgba(255, 92, 92, 0.05)");
      }
      if (type === "Actionable Actions") {
        setColor("rgba(0,0,255, 0.05)");
      }

  }, [type]);

  return (
    <Paper sx={{ backgroundColor: color }}>
      <Typography >{type}:</Typography>

      <List>
        {sentimentsArray?.length === 0 &&(
            <Typography>No sentiments for this category</Typography>
        )}
        {sentimentsArray?.map((sentiment, index) => (
          <ListItem key={index}>
            <ListItemText primary={sentiment.text} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
