import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { activateAccountAxios, checkIfLoggedIn } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Paper } from "@mui/material";
import { checkIfActivated } from "../../utils/axios";

export const Activation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleActivation = async () => {
    try {
      // TO DO I tried debugging I dont know why this request isn't returning anything, but i don't have time now
      const activationRequest = await activateAccountAxios(id);
      if (activationRequest.status === 201) {
        navigate("/onboarding");
      }
    } catch (error) {
      console.log("Activation error:", error);
    }
  };

  //   const optimisticallyRedirect = async () => {
  //     await new Promise((res) => setTimeout(res, 2500));
  //     navigate("/dashboard");
  //   };

  const checkIfUserActivated = async () => {
    const isUserActivated = await checkIfActivated();

    if (isUserActivated.status === 201) {
      navigate("/onboarding");
    } else {
      checkIfUserIsActivatedTimeout();
      checkIfLoggedIn();
    }
  };
  const checkIfUserIsActivatedTimeout = async () => {
    await new Promise((res) => setTimeout(res, 5000));
    checkIfUserActivated();
  };

  useEffect(() => {
    handleActivation();
    // optimisticallyRedirect();
    checkIfUserIsActivatedTimeout();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper marginLeft={8} marginRight={8} padding="100px">
        Redirecting to dashboard
        <CircularProgress size={100} />
      </Paper>
    </Box>
  );
};
