import * as React from "react";
import { FeedbackCreator } from "./FeedbackCreator";

export const CustomFields = () => (
  <FeedbackCreator
    header={`Our platform allows for text data analysis not only in terms of feedback but also from the perspective of any content of your choice. For instance, in the case of sales, you can provide your own criteria for analysis, such as "customer questions" or "customer requests". Other example is helpdesk in that case we could analyze in context of "what are common user issues" or "customer questions". Please specify the context in which you need to analyze the text in form below.`}
    alertContent="Application for custom analysis was successfully send."
    type="Customize analysis"
    textFieldLabel="Request custom analysis"
  />
);
