export let JWT_TOKEN = "";
export let API_TOKEN = "";

export const setApiToken = (token: string) => {
  const currentTime = new Date().getTime();
  const tokenObject = { token, time: currentTime };
  localStorage.setItem("apiToken", JSON.stringify(tokenObject));
  API_TOKEN = token;
};

export const setJwtToken = (token: string) => {
  const currentTime = new Date().getTime();
  const tokenObject = { token, time: currentTime };
  localStorage.setItem("jwtToken", JSON.stringify(tokenObject));
  JWT_TOKEN = token;
};

export const getApiToken = () => {
  const tokenObject = JSON.parse(localStorage.getItem("apiToken") || "{}");
  const tokenTime = tokenObject.time;
  const currentTime = new Date().getTime();

  if (!tokenTime || currentTime - tokenTime > 4 * 60 * 60 * 1000) {
    // Token is expired or doesn't exist
    API_TOKEN = "";
    return null;
  }

  API_TOKEN = tokenObject.token;
  return API_TOKEN;
};

export const getJwtToken = () => {
  const tokenObject = JSON.parse(localStorage.getItem("jwtToken") || "{}");
  const tokenTime = tokenObject.time;
  const currentTime = new Date().getTime();

  if (!tokenTime || currentTime - tokenTime > 4 * 60 * 60 * 1000) {
    // Token is expired or doesn't exist
    JWT_TOKEN = "";
    return null;
  }

  JWT_TOKEN = tokenObject.token;
  return JWT_TOKEN;
};
