import * as React from "react";

// TO DO add type for:
// {
//     "id": 38,
//     "name": "first project",
//     "userId": 6,
//     "createdAt": "2023-07-12T11:20:21.372Z",
//     "deleted": false
//   }
export const useProject = () => {
    const [currentProject, setCurrentProject] = React.useState({})

    return [currentProject, setCurrentProject]
}