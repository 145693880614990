import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './routes';
import './index.css'
import * as Sentry from "@sentry/react";
import { getApiToken } from './utils/JWT_API_TOKENS';
import mixpanel from 'mixpanel-browser';

Sentry.init({
  dsn: "https://a96f1046342a43e08575b78aa1537754@o4505454516305920.ingest.sentry.io/4505454517551104",
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // enabled: process.env.NODE_ENV !== 'development'
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id, user: {userID: getApiToken() ? getApiToken()?.slice(0,6) : "NO_TOKEN"} });
    }
    return event;
  },
});

if (typeof mixpanel !== 'undefined') {
  mixpanel?.init("7f502ec316c7613b77acaab14206dded");
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
 <RouterProvider router={router} />
   </React.StrictMode>,
)
