import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { mixPanelTrack } from "../../utils/mixPanel";

interface ConfirmationModalProps {
  isOpen: boolean;
  callbackConfirm: MouseEventHandler<HTMLButtonElement>;
  callbackCancel: MouseEventHandler<HTMLButtonElement>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  callbackConfirm,
  callbackCancel,
}) => {
  
  useEffect(() => {
    mixPanelTrack('DeletingModal')
  }, []);

  return (
    <Dialog open={isOpen} keepMounted onClose={callbackCancel}>
      <DialogTitle>Are you sure you want to delete?</DialogTitle>
      <DialogContent>Removing of items is permanent.</DialogContent>
      <DialogActions>
        <Button onClick={callbackCancel}>Cancel</Button>
        <Button onClick={callbackConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationModal;
