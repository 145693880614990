import React, { useState, useEffect, useCallback } from "react";
import { TextField, List, ListItem, Box, Autocomplete } from "@mui/material";
import { debounce } from "lodash";
import { searchForAppAxios } from "../../utils/axios";

export const DynamicSearch = ({
  country,
  lang,
  setGooglePlayForm,
  googlePlayForm,
}) => {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSettingAppId = (appId, title) => {
    setGooglePlayForm({
      ...googlePlayForm,
      googlePlayAppId: appId,
      appName: {
        title,
      },
    });
  };

  const fetchResults = async (query, country, lang) => {
    setLoading(true);
    const response = await searchForAppAxios(query, country, lang);
    setResults(response.data.results);
    setLoading(false);
  };

  const debouncedFetchResults = useCallback(
    debounce(() => fetchResults(searchText, country, lang), 750),
    [searchText, country, lang]
  );

  useEffect(() => {
    if (searchText) {
      setLoading(true);
      debouncedFetchResults();
    } else {
      setResults([]);
    }
    return () => {
      if (searchText) {
        debouncedFetchResults.cancel();
      }
    };
  }, [searchText, debouncedFetchResults, country, lang]);

  const noOptionsTextGenerator = () => {
    let text = "";
    if (searchText === "") {
      text = "Please type name of the app";
      return text;
    }
    if (loading) {
      text = "Loading ...";
    } else {
      text =
        "We can't find following app, you can also paste a link in box below";
    }
    return text;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        options={results}
        getOptionLabel={(option) => option.title}
        onInputChange={(event, value) => setSearchText(value)}
        value={googlePlayForm.appName}
        onChange={(event, value) =>
          handleSettingAppId(value ? value.appId : "", value ? value.title : "")
        }
        noOptionsText={noOptionsTextGenerator()}
        renderInput={(params) => (
          <TextField {...params} label="Search by app name" />
        )}
      />
    </Box>
  );
};
export default DynamicSearch;
