import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Pagination,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { format } from "date-fns";
interface IReport {
  customQuestion?: string;
  reportContent: string;
  createdAt: string;
  failedToProcess: boolean;
  analyzed: boolean;
  id: string | number;
  lackOfComments: boolean;
}

interface IProps {
  reports: IReport[];
}

const ReportsTable = ({ reports }: IProps) => {
  const itemsPerPage = 15;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(reports?.length / itemsPerPage));
  const handleChange = (event, value) => {
    setPage(value);
  };

  const slicedReports = reports.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <div>
      {slicedReports.map(
        ({
          customQuestion,
          reportContent,
          createdAt,
          failedToProcess,
          analyzed,
          lackOfComments,
          id,
        }) => {
          const showLoading =
            analyzed === false &&
            failedToProcess === false &&
            lackOfComments === false;
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {customQuestion
                    ? `Answer to question: ${customQuestion}`
                    : "Overall customer report"}
                </Typography>
                <Typography marginLeft={2} textAlign="right">
                  {format(new Date(createdAt), "dd/MM/yyyy kk:mm")}
                </Typography>
                <Typography marginLeft={3} textAlign="right">
                  <Typography color="#880808">
                    {failedToProcess &&
                      `There was an error with processing this ${
                        customQuestion ? "question" : "report"
                      }. Please contact support with this id: ${id}`}
                      {lackOfComments && "There was less the 5 comments to analyze"}
                  </Typography>

                  {showLoading && "Processing..."}
                  {showLoading && <CircularProgress size={18} />}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{reportContent}</Typography>
                {showLoading && <CircularProgress />}
                {lackOfComments && "As there were less then 5 comments we can't analyze this application"}

              </AccordionDetails>
            </Accordion>
          );
        }
      )}
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        sx={{ display: "flex", justifyContent: "right", margin: 2 }}
      />
    </div>
  );
};

export default ReportsTable;
