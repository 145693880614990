import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import PermMediaOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PublicIcon from "@mui/icons-material/Public";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import TimerIcon from "@mui/icons-material/Timer";
import SettingsIcon from "@mui/icons-material/Settings";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router";
import { Autocomplete, TextField } from "@mui/material";
import { BorderColor } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ProjectSelector from "../../Projects/ProjectSelector";
// import SearchIcon from "@mui/icons-material/Search";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ApiIcon from "@mui/icons-material/Api";
import TuneIcon from "@mui/icons-material/Tune";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { mixPanelTrack } from "../../../utils/mixPanel";

const categories = [
  {
    id: "Data",
    children: [
      // {
      //   id: 'Authentication',
      //   icon: <PeopleIcon />,
      //   active: true,
      // },
      { id: "Statistics", icon: <DashboardIcon />, route: "/dashboard" },
      {
        id: "Analyzed texts",
        icon: <DnsRoundedIcon />,
        route: "/analyzedTexts",
      },
      // TO DO REVERSE THIS WHEN text add will be added
      // { id: "Add text to analyze", icon: <AddIcon />, route: "/addText" },
      // { id: "Search", icon: <SearchIcon />, route: "/search" },

      { id: "Reports & Questions", icon: <PublicIcon />, route: "/reports" },
          // TO DO REVERSE THIS WHEN project add will be added
      // {
      //   id: "Google Play Market",
      //   icon: <PlayArrowIcon />,
      //   route: "/playmarket",
      // },
      // {
      //   id: "Machine learning",
      //   icon: <SettingsInputComponentIcon />,
      // },
    ],
  },
  {
    id: "Settings",
    children: [
      {
        id: "User & Plan settings",
        icon: <SettingsIcon />,
        route: "/settings",
      },
      { id: "API access", icon: <ApiIcon />, route: "/apiRequest" },
      { id: "Customize analysis ", icon: <TuneIcon />, route: "/customFields" },
      {
        id: "Support & contact",
        icon: <HelpCenterIcon />,
        route: "/helpcenter",
      },
      // { id: 'Performance', icon: <TimerIcon /> },
      // { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
];

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    if (route?.includes(pathname)) {
      return;
    }
    navigate(route);
    mixPanelTrack('ClickedNavigation' + route)
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          Sentiment Scanner
        </ListItem>
        {/* <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Project Overview</ListItemText>
        </ListItem> */}

        {/* // TO DO REVERSE THIS WHEN project will be added */}
        <ListItem
          sx={{
            ...item,
            ...itemCategory,
            ...{ backgroundColor: "rgba(0, 155, 229, 0.08)" },
          }}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ProjectSelector />
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton
                  selected={route?.includes(pathname)}
                  sx={item}
                  onClick={() => handleNavigate(route)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
