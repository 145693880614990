import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { Paper, Typography, Box } from "@mui/material";
import { checkIfActivated } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { mixPanelTrack } from "../../utils/mixPanel";
import { Copyright } from "../CopyrightNotice/CopyrightNotice";

export const ActivationNotice = () => {
  const navigate = useNavigate();

  const checkIfUserActivated = async () => {
    const isUserActivated = await checkIfActivated();

    if (isUserActivated.status === 201) {
      navigate("/onboarding");
    } else {
      checkIfUserIsActivatedTimeout();
    }
  };
  const checkIfUserIsActivatedTimeout = async () => {
    await new Promise((res) => setTimeout(res, 5000));
    checkIfUserActivated();
  };
  useEffect(() => {
    checkIfUserIsActivatedTimeout();
    mixPanelTrack('Activation')
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        maxWidth: "300px",
        margin: "auto",
        padding: "50px"
      }}
    >
      <Paper
        marginLeft={8}
        marginRight={8}
        padding="100px"

      >
        <Paper
        >
          <Typography>
            Please activated you account by clicking link in you email. If you
            have any issues contact: contact@sentimentscanner.com
          </Typography>
        </Paper>
      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
  );
};
