import {
  Paper,
  AppBar,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
} from "@mui/material";
import * as React from "react";
import ISO6391 from "iso-639-1";
import { iso31661 } from "iso-3166";
import { postRequestGooglePlay } from "../../utils/axios";
import AlertNotification from "../AlertComponents/Alert";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { validateGooglePlayURL } from "./validateGooglePlay";
import { mixPanelTrack } from "../../utils/mixPanel";

export const PlayMarket = () => {
  const [url, setUrl] = React.useState("");
  const [validationError, setValidationError] = React.useState(false);
  const [successVisibility, setSuccessVisibility] = React.useState(false);
  const [alertVisibility, setAlertVisibility] = React.useState(false);
  const [languageCode, setLanguageCode] = React.useState("en");
  const [countryCode, setCountryCode] = React.useState("US");

  const [errorMessage, setErrorMessage] = React.useState(
    "There was an error when tried to analyze this Google Play URL"
  );
  const [scrapperFrequency, setScrapperFrequency] = React.useState("");
  const [commentsLength, setCommentsLength] = React.useState(100);

  const handleSettingFrequency = (e) => {
    setScrapperFrequency(e.target.value);
  };

  const handleCommentChange = (e) => {
    setCommentsLength(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    mixPanelTrack('handleUrlChange')
  };

  const handleCountryChange = (e, newValue) => {
    const countryCode = iso31661.find((item) => item.name === newValue);
    setCountryCode(countryCode.alpha2);
    mixPanelTrack('handleCountryChange')

  };

  const handleLanguageChange = (e, newValue) => {
    const languageCode = ISO6391.getCode(newValue);
    setLanguageCode(languageCode);
    mixPanelTrack('handleLanguageChange')

  };

  const handleSendingRequestForScraping = async () => {
    const validationSuccess = validateGooglePlayURL(url);
    if (validationSuccess === true) {
      const googlePlayRequest = await postRequestGooglePlay({
        url,
        commentsLength: Number(commentsLength),
        scrapperFrequency,
        createReportAfter: true,
        languageCode,
        countryCode,
      });
      console.log(googlePlayRequest);
      const statusCode = googlePlayRequest?.response?.status;
      if (googlePlayRequest.status === 200) {
        setUrl("");
        setSuccessVisibility(true);
        return;
      }
      setAlertVisibility(true);
      if (statusCode === 429) {
        setErrorMessage(
          "No more available requests for getting Google Play comments"
        );
      } else {
        setErrorMessage(
          "There was an error when tried to analyze this Google Play URL"
        );
      }
    } else {
      setValidationError(true);
    }
  };

  const countryCodes = iso31661
    .map((country) => country.name)
    .filter((name) => name !== "Russia");

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        ></AppBar>
        <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
          The retrieval of comments from Google Play can be performed on a
          weekly, bi-weekly, or monthly basis, and they will be analyzed and
          incorporated into the texts table. You can also request retrieval
          instantly. You will receive notification email after comments have
          been analyzed. The amount of retrieved and processed texts depend on
          you current plan. You will be billed only for analyzed comments.
          Example Google App URL:
          https://play.google.com/store/apps/details?id=com.google.android.gm
        </Paper>

        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content={
            <>Google Play application was successfully added for analyzing.</>
          }
        />
        <AlertNotification
          type="error"
          alertVisibility={alertVisibility}
          setAlertVisibility={setAlertVisibility}
          title="Error"
          content={errorMessage}
        />
        <AlertNotification
          type="error"
          alertVisibility={validationError}
          setAlertVisibility={setValidationError}
          title="Error"
          content="Invalid Google Play URL"
        />
        <Paper
          sx={{
            marginTop: 2,
            marginBottom: 2,
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container xs={12}>
            <Grid item xs={6} sx={{ marginRight: 1 }}>
              <TextField
                value={url}
                onChange={handleUrlChange}
                label="Google Play URL"
                id="googlePlayInput"
                fullWidth
                sx={{ marginRight: 1 }}
                error={validationError}
              />
            </Grid>
            <Grid item xs={2} sx={{ marginRight: 1 }}>
              <TextField
                value={commentsLength}
                onChange={handleCommentChange}
                label="Comments length"
              />
            </Grid>
            <Grid item xs={2} sx={{ marginRight: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Frequency</InputLabel>
                <Select
                  labelId=""
                  value={scrapperFrequency}
                  onChange={handleSettingFrequency}
                >
                  <MenuItem value="">just once this time</MenuItem>
                  <MenuItem value="1week">weekly</MenuItem>
                  <MenuItem value="2weeks">biweekly</MenuItem>
                  <MenuItem value="1month">monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Autocomplete
              disablePortal
              id="country"
              defaultValue="United States of America"
              onChange={handleCountryChange}
              options={countryCodes}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
            />
            <Autocomplete
              disablePortal
              id="language"
              defaultValue="English"
              onChange={handleLanguageChange}
              options={ISO6391.getAllNames()}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Language" />
              )}
            />

            <Grid item xs={1}>
              <Button
                variant="outlined"
                sx={{ marginLeft: 1 }}
                onClick={handleSendingRequestForScraping}
              >
                Get comments
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          Currently this is work in progress and get 100 text at the time
          without scheduling.
        </Paper>
        {/* <Paper sx={{ marginTop: 2 }}>
          Curently your plan alows you to set up bi-weekly and monthly reports
          and retrieve 100 comments. / Tutaj 3 rozne opcje. \ Tutaj pokazane ile
          aktualnie masz tekstow w bazie danych: currentTextCount i ile moze
          obrac. If you wish to increase the amount of the aviable texts to
          analyze consider deleting old texts or upgrade your plan.
        </Paper> */}
      </Paper>
    </Overlay>
  );
};
