import * as React from "react";
import AlertNotification from "../AlertComponents/Alert";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { Paper, AppBar, TextField, Button, Link, Box } from "@mui/material";
import { addNewFeedbackAxios } from "../../utils/axios";
import { mixPanelTrack } from "../../utils/mixPanel";

export const FeedbackCreator = ({
    header,
    alertContent,
    type,
    textFieldLabel
}) => {
  const [content, setContent] = React.useState("");
  const [successVisibility, setSuccessVisibility] = React.useState(false);
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleFeedbackRequest = async () => {
    addNewFeedbackAxios({
      content,
      type
    });
    setSuccessVisibility(true)
    setContent('')
    mixPanelTrack('Feedback' + type)
  };

  if(type === 'PLAN_CHANGE'){
    return(
      <Box>
        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content={alertContent}
        />
        <TextField
          value={content}
          onChange={handleOnChange}
          label={textFieldLabel}
          id="apiAccess"
          fullWidth
          multiline
          rows={4}
          maxRows={16}
          sx={{ margin: 1 }}
        />
        <Button
          variant="outlined"
          sx={{ margin: 1 }}
          onClick={handleFeedbackRequest}
        >
          Send
        </Button>
      </Box>
    )
  }

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <Paper sx={{margin: 1}}>
          {header}
        </Paper>
        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content={alertContent}
        />
        <TextField
          value={content}
          onChange={handleOnChange}
          label={textFieldLabel}
          id="apiAccess"
          fullWidth
          multiline
          rows={4}
          maxRows={16}
          sx={{ margin: 1 }}
        />
        <Button
          variant="outlined"
          sx={{ margin: 1 }}
          onClick={handleFeedbackRequest}
        >
          Send
        </Button>
      </Paper>
    </Overlay>
  );
};
