import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { requestResetPasswordAxios } from "../../utils/axios";
import { Alert, AlertTitle } from "@mui/material";
import { mixPanelTrack } from "../../utils/mixPanel";
import { Copyright } from "../CopyrightNotice/CopyrightNotice";
import AlertNotification from "../AlertComponents/Alert";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function RequestPasswordReset() {
  const navigate = useNavigate();
  const [error, setError] = useState(false); // or errors array
  const [errorMessage, setErrorMessage] = useState("");
  const [successVisibility, setSuccessVisibility] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mixPanelTrack("RequestPasswordReset");

    const data = new FormData(event.currentTarget);
    const requestReset = await requestResetPasswordAxios({
      email: data.get("email"),
    });

    if (requestReset.status === 200) {
      setSuccessVisibility(true);
    } else {
      // user not activated
      if (requestReset.response.status === 401) {
        setErrorMessage(
          "Please activated your account by clicking link in you email. If you have any issues contact: contact@sentimentscanner.com"
        );
      } else {
        setErrorMessage("There is an error with provided email .");
      }

      setError(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && (
          <Alert severity="error">
            <AlertTitle>{errorMessage}</AlertTitle>
          </Alert>
        )}
        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content="Please check your email for rest password link"
        />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Request Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Do you remember password? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
