import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordAxios } from "../../utils/axios";
import { Alert, AlertTitle } from "@mui/material";
import { mixPanelTrack } from "../../utils/mixPanel";
import { Copyright } from "../CopyrightNotice/CopyrightNotice";
import AlertNotification from "../AlertComponents/Alert";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PasswordReset() {
  const navigate = useNavigate();
  const { token } = useParams();
  console.log("##", token);
  const [error, setError] = useState(false); // or errors array
  const [errorMessage, setErrorMessage] = useState("");
  const [successVisibility, setSuccessVisibility] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mixPanelTrack("PasswordReset");

    const data = new FormData(event.currentTarget);
    const loginRequest = await resetPasswordAxios({
      token,
      password: data.get("password"),
    });

    if (loginRequest.status === 200) {
      setSuccessVisibility(true);
    } else {
      // user not activated
      if (loginRequest.response.status === 401) {
        setErrorMessage("Please contact: contact@sentimentscanner.com");
      } else {
        setErrorMessage(
          "The provided link is incorrect or expired, try to request password link again or contact@sentimentscanner.com"
        );
      }

      setError(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && (
          <Alert severity="error">
            <AlertTitle>{errorMessage}</AlertTitle>
          </Alert>
        )}
        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content="Your password has been changed successfully"
        />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              inputProps={{ minLength: 8 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Do you remember password? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
