import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Button, Grid } from "@mui/material";
import { deleteAllAxios, getProjects } from "../../utils/axios";
import { useProject } from "./useProjectHook";
import ConfirmationModal from "../ConfiramtionModal/ConfrimationModal";
import { useState } from "react";
import ConfirmationModalChangeProject from "../ConfiramtionModal/ConfirmationModalChangeProject";
import { mixPanelTrack } from "../../utils/mixPanel";

const ProjectSelector = () => {
  const [projectsList, setProjectsList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useProject();
  const navigate = useNavigate();
  const lastProject = projectsList[projectsList.length - 1];

  const handleGettingProjects = async () => {
    const projectsRequest = await getProjects();
    setProjectsList(projectsRequest.data.projects);
  };

  const handleChangingProject = (value) => {
    setCurrentProject(value);
  };

  React.useEffect(() => {
    handleGettingProjects();
  }, []);

  React.useEffect(() => {
    // TO DO fix this, this is temporary as user whos project is without data should be redirected
    if (lastProject?.googlePlayLackOfComments) {
      navigate("/loadingReport", {
        state: { id: lastProject.id },
      });
    }
  }, [projectsList]);

  const handleOpeningModal = () => {
    mixPanelTrack("Clicked change project");
    setModalOpen(true);
  };

  const handleChange = async () => {
    mixPanelTrack("Deleted all projects");
    await deleteAllAxios()
    navigate("/onboarding");

  }
  return (
    <>
      <ConfirmationModalChangeProject
        callbackConfirm={handleChange}
        callbackCancel={() => setModalOpen(false)}
        isOpen={modalOpen}
      />
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid sm={9} item>
          App: {lastProject?.googlePlayName}
        </Grid>
        <Grid sm={3} item>
          <Button onClick={handleOpeningModal}>Change App</Button>
        </Grid>
      </Grid>
    </>
  );
  // console.log('currentProject', currentProject)

  // TO DO tutaj jest jakiś błąd zdaje sie ze w kolko to sie rerenderuje

  return (
    <Autocomplete
      disablePortal
      disableClearable
      id="projectSelect"
      value={currentProject}
      options={projectsList}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => handleChangingProject(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Current Project"
          sx={{
            color: "white",
            "#projectSelect-label": { color: "white" },
            "#projectSelect": { color: "white" },
            ".MuiOutlinedInput-notchedOutline": { borderColor: "white" },
            ".MuiButtonBase-root": { color: "white" },
          }}
        />
      )}
      sx={{ width: "100%" }}
      color="white"
    />
  );
};

export default ProjectSelector;
