import {
  Paper,
  AppBar,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Alert,
  AlertTitle,
  Fade,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
  postReportAxios,
  getReportListAxios,
  postReportCustomQuestionAxios,
  getUserSettingsAxios,
} from "../../utils/axios";
import { mixPanelTrack } from "../../utils/mixPanel";
import AlertNotification from "../AlertComponents/Alert";
import Overlay from "../DashboardOverlayContainer/Overlay";
import ReportsTable from "./ReportsTable";

export const Report = () => {
  const [question, setQuestion] = React.useState("");
  const [reports, setReports] = React.useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [alertContent, setAlertContent] = useState(
    "There was an error when submitting the question for report"
  );
  const [availableRequests, setAvailableRequests] = useState(null);
  const [loading, setLoading] = useState(false);

  const requestNewReport = async () => {
    
    setSuccessVisibility(true);

    const reportData = await postReportAxios();
    getReportList({ ignoreLoading: true });
  };

  const getUserSetting = async () => {
    const getUserSettingsRequest = await getUserSettingsAxios();
    if (getUserSettingsRequest.status === 200) {
      setAvailableRequests(getUserSettingsRequest.data.leftReports);
    }
  };

  React.useEffect(() => {
    getUserSetting();
  }, []);

  const poorManListener = async () => {
    await new Promise((res) => setTimeout(res, 5500));
    getReportList({ ignoreLoading: true });
  };

  const getReportList = async ({ ignoreLoading }) => {
    if (!ignoreLoading) {
      setLoading(true);
    }
    const reports = await getReportListAxios();
    // only set if value is different from actual
    if (JSON.stringify(reports.data) !== JSON.stringify(reports)) {
      setReports(reports.data);
    }
    let isAnyReportProcessed = false;
    reports.data.map((report) => {
      if (report.analyzed === false && report.failedToProcess === false) {
        isAnyReportProcessed = true;
      }
    });
    if (isAnyReportProcessed) {
      poorManListener();
    }
    if (!ignoreLoading) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getReportList({ ignoreLoading: false });
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const handleAskQuestionButton = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    mixPanelTrack('handleAskQuestionButton')

    if (question !== "") {
      const addQuestionRequest = await postReportCustomQuestionAxios(question);
      if (addQuestionRequest.status === 200) {
        setQuestion("");
        setSuccessVisibility(true);
        getReportList({ ignoreLoading: false });
      } else {
        setAlertContent(
          "There was an error when submitting the question for report"
        );
        if (addQuestionRequest.request.status == 429) {
          setAlertContent(
            "No more available requests for reports functionality. "
          );
        }
        setAlertVisibility(true);
      }
    }
  };

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        ></AppBar>
        <Typography>
          Available Report/Question Requests: {availableRequests}
        </Typography>
        
        {/* // TO DO REVERSE THIS WHEN text add will be added
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={requestNewReport}
        >
          Request report of all data
        </Button>
 */}

        <AlertNotification
          type="success"
          alertVisibility={successVisibility}
          setAlertVisibility={setSuccessVisibility}
          title="Success"
          content="Report was successfully added for analyzing. After analyze you will also receive email with copy of the report."
        />
        {reports?.length !== 0 && <ReportsTable reports={reports} />}
        {reports?.length === 0 && loading === false && (
          <Typography>
            There are no reports or answers to question to be displayed
          </Typography>
        )}
        {loading && <CircularProgress />}

        <AlertNotification
          type="error"
          alertVisibility={alertVisibility}
          setAlertVisibility={setAlertVisibility}
          title="Error"
          content={alertContent}
        />
        <Box component="form" onSubmit={handleAskQuestionButton} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="content"
              required
              fullWidth
              id="content"
              label="Ask question about data"
              autoFocus
              multiline
              rows={4}
              maxRows={16}
              onChange={handleOnChange}
              value={question}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleAskQuestionButton}
          >
            Ask question
          </Button>
        </Box>
      </Paper>
    </Overlay>
  );
};
