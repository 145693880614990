import React, { useCallback, useEffect, useState } from "react";
import {
  deleteTextsAxios,
  getAllUsersTextsAxios,
  getSearchUsersTextsAxios,
  getTextExcelAxios,
} from "../../utils/axios";
import Overlay from "../DashboardOverlayContainer/Overlay";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Table from "./TextTableReactTable/TextTableReactTable";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../ConfiramtionModal/ConfrimationModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { mixPanelTrack } from "../../utils/mixPanel";

const TextTable = () => {
  const [gridData, setGridData] = useState([]);
  const [processingCount, setProcessingCount] = useState(0);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getUserTexts = async () => {
    setLoading(true);
    const tableData = await getAllUsersTextsAxios();
    setGridData(tableData.data.userTexts);
    setProcessingCount(tableData.data.processingTextCount);
    setLoading(false);
  };

  React.useEffect(() => {
    if (search === "") {
      // get search on init and get search when user resets the search field to ""
      getUserTexts();
    }
  }, [search]);

  const downloadExcel = () => {
    getTextExcelAxios();
    mixPanelTrack('downloadExcel')
  };

  const sendRequest = useCallback(
    debounce(async (value) => {
      setLoading(true);
      const tableData = await getSearchUsersTextsAxios(value);
      setGridData(tableData.data);
      setLoading(false);
    }, 1000),
    []
  );

  const handleSearch = (event) => {
    setSearch(event.target.value);
    mixPanelTrack('handleSearch')
  };

  useEffect(() => {
    if (search) {
      sendRequest(search);
    }
  }, [search, sendRequest]);

  const handleDelete = async () => {
    await deleteTextsAxios(selected); // TO DO add error handling
    getUserTexts(); // TO DO add confrimation alert
    setSelected([]);
    setModalOpen(false);
  };

  const handleDeleteModal = async () => {
    setModalOpen(true);
  };

  return (
    <Overlay>
      <Box sx={{ maxWidth: "90vw" }}>
        {selected?.length !== 0 && (
          <IconButton onClick={handleDeleteModal}>
            <DeleteIcon sx={{ marginBottom: "10px" }} id="deleteTextIcon" />
            <Typography sx={{ paddingBottom: 1 }}>
              ({selected.length ? selected.length : ""})
            </Typography>
          </IconButton>
        )}
        <ConfirmationModal
          callbackConfirm={handleDelete}
          callbackCancel={() => setModalOpen(false)}
          isOpen={modalOpen}
        />

        <TextField
          id="searchAll"
          label="Search all fields"
          onChange={handleSearch}
          value={search}
        />

        <Button
          onClick={() => downloadExcel()}
          variant="outlined"
          sx={{ marginRight: 2, marginLeft: 2, marginBottom: 2, height: 55 }}
        >
          Export to excel
        </Button>
        {processingCount > 0 && (
          <Typography>
            We are currently processing: {processingCount}
          </Typography>
        )}
      </Box>
      {/* <button onClick={() => autoSizeAll(true)}>
            Auto-Size All (Skip Header)
          </button> */}

      {/* <Button
            onClick={() =>
              setDefaultColDef({
                sortable: true,
                resizable: true,
                filter: true,
                wrapText: true,
                autoHeight: true, // <- this
              })
            }
            variant="outlined"
          >
            Adjust column height to fit all text
          </Button> */}

      {loading && <CircularProgress size={100} />}
      {loading && <Typography fontSize={40}>Loading...</Typography>}

      {!loading && gridData?.length === 0 ? (
        <Typography marginTop={20} marginBottom={20}>
          You currently don't have any texts
        </Typography>
      ) : null}
      <Table
        tableData={gridData}
        navigate={navigate}
        setSelected={setSelected}
      />
    </Overlay>
  );
};

export default TextTable;
