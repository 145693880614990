import React, { useEffect, useState } from "react";
import {
  format,
  min,
  max,
  parseISO,
  isValid,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
} from "date-fns";

import {
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { getDashboardDataAxios } from "../../utils/axios";
import "./fixRecharts.css";

const DashboardList = ({ list, type }) => {
  const [color, setColor] = useState("");

  useEffect(() => {
    if (type === "Positive Sentiments") {
      setColor("rgba(46, 255, 46, 0.05)");
    }
    if (type === "Negative Sentiments") {
      setColor("rgba(255, 92, 92, 0.05)");
    }
    if (type === "Actionable Actions") {
      setColor("rgba(0,0,255, 0.05)");
    }
  }, [type]);

  return (
    <List
      sx={{
        maxHeight: 300,
        overflow: "auto",
        position: "relative",
        backgroundColor: color,
      }}
    >
      {list.length === 0 && "You don't have any feedback for this category"}
      {list.map(({ text }, index) => (
        <li key={index}>
          <ul>
            <ListItem>
              <ListItemText primary={text} />
            </ListItem>
          </ul>
        </li>
      ))}
    </List>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = React.useState(true);
  const [timeframe, setTimeframe] = React.useState("Day");
  const [rawChartData, setRawChartData] = React.useState([]);

  const [dashboardData, setDashboardData] = React.useState({
    positiveSentiment: [],
    negativeSentiment: [],
    actionableItems: [],
    textCount: 0,
    npsScores: {
      median: 0,
      avg: 0,
    },
    firstDate: "",
    lastDate: "",
    dataForChart: [],
  });

  const getUserTexts = async () => {
    setLoading(true);
    const { data } = await getDashboardDataAxios(true);
    setRawChartData(data.dataForChart);
    const parsedDates = data.dates
      .filter((x) => x)
      .map((date) => parseISO(date));

    const earlyDate = min(parsedDates);
    const lastDate = max(parsedDates);
    if (data.dataForChart.length) {
      const parsedDatesForCharts = data.dataForChart.map((x) => {
        return {
          ...x,
          contentDate: x.contentDate
            ? format(parseISO(x.contentDate), "dd/MM/yyyy")
            : null,
        };
      });
      setDashboardData({
        ...data,
        dataForChart: parsedDatesForCharts,
        firstDate: format(earlyDate, "dd/MM/yyyy"),
        lastDate: format(lastDate, "dd/MM/yyyy"),
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getUserTexts();
  }, []);

  useEffect(() => {
    if (!rawChartData.length) {
      return;
    }
    const timeFrameFormatMap = {
      Day: "dd/MM/yyyy",
      Week: (date) => format(startOfWeek(date), "dd/MM/yyyy"),
      Month: (date) => format(startOfMonth(date), "MM/yyyy"),
      Quarter: (date) => format(startOfQuarter(date), "QQQ/yyyy"),
    };

    const parsedDatesForCharts = rawChartData.map((x) => {
      const formatFn =
        typeof timeFrameFormatMap[timeframe] === "function"
          ? timeFrameFormatMap[timeframe]
          : (date) => format(date, timeFrameFormatMap[timeframe]);

      return {
        ...x,
        contentDate: x.contentDate ? formatFn(parseISO(x.contentDate)) : null,
      };
    });

    const mergedDatesForCharts = parsedDatesForCharts.reduce((acc, curr) => {
      const existingIndex = acc.findIndex(
        (item) => item.contentDate === curr.contentDate
      );

      if (existingIndex > -1) {
        const existingItem = acc[existingIndex];

        acc[existingIndex] = {
          ...existingItem,
          positiveSentiments:
            existingItem.positiveSentiments + curr.positiveSentiments,
          negativeSentiments:
            existingItem.negativeSentiments + curr.negativeSentiments,
          actionableActions:
            existingItem.actionableActions + curr.actionableActions,
          items: existingItem.items + curr.items,
          npsTotal: existingItem.npsTotal + curr.npsTotal,
          npsAverage: (existingItem.npsAverage + curr.npsAverage) / 2,
        };
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    setDashboardData({
      ...dashboardData,
      dataForChart: mergedDatesForCharts,
    });
  }, [rawChartData, timeframe]);

  const {
    positiveSentiment,
    negativeSentiment,
    actionableItems,
    textCount,
    npsScores,
  } = dashboardData;
  const positiveLength = positiveSentiment.length;
  const negativeLength = negativeSentiment.length;
  const actionableLength = actionableItems.length;

  if (loading)
    return <Overlay>{loading && <CircularProgress size={100} />}</Overlay>;

  return (
    <Overlay>
      <Paper>
        AnalyzedTexts: {textCount} | Median NPS Score: {npsScores.median} |
        Average NPS Score: {npsScores.avg}
        <Typography color="#d3d3d3">
          NPS Score is measuring loyalty of customer from 100 (will return) to
          -100 (won't return).
        </Typography>
      </Paper>
      <Paper>
        Analyzed texts from {dashboardData.firstDate} to{" "}
        {dashboardData.lastDate}
      </Paper>
      <Grid container>
        <Grid item md={4} marginTop={2} sx={{ boxShadow: 2 }}>
          Positive {positiveLength ? `(${positiveLength})` : ""}:
          <DashboardList list={positiveSentiment} type="Positive Sentiments" />
        </Grid>
        <Grid item md={1} />
        <Grid item md={3} marginTop={2} sx={{ boxShadow: 2 }}>
          Negative {negativeLength ? `(${negativeLength})` : ""}:
          <DashboardList list={negativeSentiment} type="Negative Sentiments" />
        </Grid>
        <Grid item md={1} />
        <Grid item md={3} marginTop={2} sx={{ boxShadow: 2 }}>
          Actionable {actionableLength ? `(${actionableLength})` : ""}:
          <DashboardList list={actionableItems} type="Actionable Sentiments" />
        </Grid>
      </Grid>

      {dashboardData.dataForChart?.length ? (
        <Grid container>
          <Grid item sm={12} m={1} marginTop={2}>
            Select chart timeframe: {"  "}
            <Select
              label="Timeframe:"
              value={timeframe}
              onChange={(e) => {
                setTimeframe(e.target.value);
              }}
            >
              <MenuItem value="Day">Day</MenuItem>
              <MenuItem value="Week">Week</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Quarter">Quarter</MenuItem>
            </Select>
          </Grid>
          <Grid item sm={12} marginTop={2} sx={{ boxShadow: 2 }}>
            <ResponsiveContainer height={400} width="100%">
              <LineChart data={dashboardData.dataForChart}>
                <CartesianGrid strokeDasharray="30 30" />
                <XAxis dataKey="contentDate" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="negativeSentiments"
                  stroke="red"
                  activeDot={{ r: 3 }}
                />
                <Line
                  type="monotone"
                  dataKey="positiveSentiments"
                  stroke="green"
                  activeDot={{ r: 3 }}
                />
                <Line
                  type="monotone"
                  dataKey="actionableActions"
                  stroke="grey"
                  activeDot={{ r: 3 }}
                />
                <Line
                  type="monotone"
                  dataKey="items"
                  name="analyzed texts"
                  stroke="grey"
                  activeDot={{ r: 1 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>

          <Grid item sm={12} marginTop={2} sx={{ boxShadow: 2 }}>
            <ResponsiveContainer height={400} width="99%">
              <LineChart data={dashboardData.dataForChart}>
                <CartesianGrid strokeDasharray="30 30" />
                <XAxis dataKey="contentDate" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="npsAverage"
                  stroke="green"
                  activeDot={{ r: 3 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      ) : (
        <Typography margin={2}>
          You don't currently have any feedback to be displayed
        </Typography>
      )}
    </Overlay>
  );
};

export default Dashboard;
