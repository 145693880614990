import mixpanel from 'mixpanel-browser';

if (typeof mixpanel !== 'undefined') {
  mixpanel?.init("7f502ec316c7613b77acaab14206dded");
}

export const mixPanelTrack = (name: string) => {
  if (typeof mixpanel !== 'undefined') {
    mixpanel?.track(name);
  }
};
