import {
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  addNewProjectOnboardingAxios,
  checkIfLoggedIn,
  postRequestGooglePlay,
} from "../../utils/axios";
import { mixPanelTrack } from "../../utils/mixPanel";
import AlertNotification from "../AlertComponents/Alert";
import { SetupGooglePlay } from "./SetupGooglePlay";

// "Select type of project",
const STEPS = ["Setup google play app", "Create automatic report"];
const CURRENT_MAX = 300;

export const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [validationError, setValidationError] = useState(false);
  const navigate = useNavigate();

  const [scrapperFrequency, setScrapperFrequency] = useState("1week");
  const [commentsLength, setCommentsLength] = useState(75);
  const [url, setUrl] = useState("");

  const [googlePlayForm, setGooglePlayForm] = useState({
    googlePlayAppId: "",
    languageCode: "en",
    languageName: "English",
    countryCode: "us",
    countryName: "United States of America",
    url: "",
    appName: {
      title: "",
    },
  });

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  const handleCommentChange = (e) => {
    const value = e.target.value;
    if (Number(value) == "NaN" || Number(value) == 0 || value > CURRENT_MAX) {
      return;
    } else {
      setCommentsLength(e.target.value);
    }
  };

  const handleSettingFrequency = (e) => {
    const value = e.target.value?.replace("once", "");
    mixPanelTrack("handleSettingFrequency");
    setScrapperFrequency(value);
  };

  const handleNext = () => {
    mixPanelTrack("handleNextOnboarding");
    if (activeStep === 1) {
      handleAddingNewProject();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleBack = () => {
    mixPanelTrack("handleBackOnboarding");
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleAddingNewProject = async () => {
    const { googlePlayAppId, languageCode, countryCode } = googlePlayForm;
    // TO DO add error handling
    const adNewProjectRequest = await addNewProjectOnboardingAxios({
      name: "GooglePlay app id:" + googlePlayAppId,
      isGooglePlay: true,
      googlePlayAppId,
      googlePlayName: googlePlayForm.appName.title,
    });

    if (adNewProjectRequest.status === 200) {
      await postRequestGooglePlay({
        googlePlayAppId,
        commentsLength: Number(commentsLength),
        scrapperFrequency,
        createReportAfter: true,
        languageCode,
        countryCode,
        projectId: adNewProjectRequest.data.id,
      });
      navigate("/loadingReport", {
        state: { id: adNewProjectRequest.data.id },
      });
    } else {
      setValidationError(true);
      console.error("ERROR", handleAddingNewProject);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        marginLeft={8}
        marginRight={8}
        sx={{
          minHeight: "300px",
          padding: 5,
          minWidth: "50%",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <AlertNotification
          type="error"
          alertVisibility={validationError}
          setAlertVisibility={setValidationError}
          title="Error"
          content="Invalid Google Play URL"
        />
        <Box marginBottom={4}>
          <Stepper activeStep={activeStep}>
            {STEPS.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "auto",
          }}
        >
          {activeStep === 0 ? (
            <SetupGooglePlay
              googlePlayForm={googlePlayForm}
              setGooglePlayForm={setGooglePlayForm}
              url={url}
              setUrl={setUrl}
            />
          ) : null}
          {activeStep === 1 ? (
            <>
              <Box maxWidth="700px" sx={{ margin: "auto" }}>
                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                  <Typography>
                    Decide the frequency at which you wish to receive reports
                    about user feedback on your app.
                  </Typography>
                  <Typography>
                    Under your current plan, you can analyze up to <b>300</b>
                    comments per month. If you're interested in increasing this
                    limit during our beta phase, please reach out to our support
                    team at contact@chrystianschutz.com.
                  </Typography>
                </Paper>
              </Box>

              <FormControl fullWidth>
                <InputLabel>
                  Frequency of getting data and generating report of google play
                  app
                </InputLabel>
                <Select
                  labelId=""
                  value={scrapperFrequency}
                  onChange={handleSettingFrequency}
                >
                  <MenuItem value="once">just once this time</MenuItem>
                  <MenuItem value="1week">weekly</MenuItem>
                  <MenuItem value="2weeks">biweekly</MenuItem>
                  <MenuItem value="1month">monthly</MenuItem>
                </Select>
              </FormControl>
              <Box marginTop={2}>
                <TextField
                  width="300px"
                  value={commentsLength}
                  onChange={handleCommentChange}
                  label="How many comment to process?"
                  type="number"
                />
              </Box>
              <Typography
                fontWeight="bold"
                marginTop={2}
                marginBottom={2}
                textAlign="center"
              >
                You will receive a free report on your email after finishing
                setup.{" "}
              </Typography>
            </>
          ) : null}
        </Box>
        {/* <Typography textAlign="center" >Step {activeStep + 1}</Typography> */}
        <Box sx={{ marginTop: "auto" }}>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              sx={{ display: activeStep === 0 ? "none" : "inherit" }}
              show={activeStep === 1}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={handleNext}
              disabled={!googlePlayForm.googlePlayAppId}
            >
              {activeStep === 1 ? "Finish setup" : "Next"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
