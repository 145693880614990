import {
  Paper,
  AppBar,
  Typography,
  Box,
  Grid,
  TextField,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleText } from "../../../utils/axios";
import Overlay from "../../DashboardOverlayContainer/Overlay";
import { SentimentsList } from "./SentimentsList";

export const ViewSingleText = () => {
  const { textId } = useParams();
  const navigate = useNavigate();
  const [singleTextData, setSingleTextData] = useState(null);
  const [status, setStatus] = useState("Loading");

  const getUserTexts = async () => {
    const addTextRequest = await getSingleText(textId);
    if (addTextRequest.status === 200) {
      setSingleTextData(addTextRequest.data);
    } else {
      setStatus("Failed to process");
    }
  };
  React.useEffect(() => {
    getUserTexts();
  }, []);

  const poorManListener = async () => {
    await new Promise((res) => setTimeout(res, 1000));
    getUserTexts();
  };

  React.useEffect(() => {
    if (singleTextData) {
      const { analyzed, processing, failedToProcess } = singleTextData;
      if (failedToProcess) {
        setStatus("Failed to process");
      }
      if (processing) {
        setStatus("Processing request");
        console.log('Processing request')
        poorManListener();
      }
      if (analyzed) {
        setStatus("Analyzed successfully");
      }
    }
  }, [singleTextData]);

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", padding: 1 }}
        >
          <Grid container>
            <Grid item md={1}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              md={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography>Status: {status}</Typography>
            </Grid>
          </Grid>
        </AppBar>
        {status === "Failed to process" && (
          <Alert severity="error" sx={{ textAlign: "center" }}>
            <AlertTitle>Error with displaying following text</AlertTitle>
          </Alert>
        )}

        <Box sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          {singleTextData && (
            <>
              <Grid container marginBottom={2}>
                <Grid item md={9} marginRight={1}>
                  <TextField
                    label="Content"
                    value={singleTextData.content}
                    multiline
                    maxRows={5}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    label="NSP score"
                    value={singleTextData.npsScore}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={4} padding={1}>
                  <SentimentsList
                    type="Positive Sentiments"
                    sentimentsArray={singleTextData.positiveSentiments}
                  />
                </Grid>
                <Grid item md={4} padding={1}>
                  <SentimentsList
                    type="Negative Sentiments"
                    sentimentsArray={singleTextData.negativeSentiments}
                  />
                </Grid>
                <Grid item md={4} padding={1}>
                  <SentimentsList
                    type="Actionable Items"
                    sentimentsArray={singleTextData.actionableActions}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Paper>
    </Overlay>
  );
};
