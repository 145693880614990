
  export const validateGooglePlayURL = (url:string):boolean => {
    let googlePlayURL;

    try {
        googlePlayURL = new URL(url);
    } catch (_) {
        return false;  // URL is not valid
    }

    // Check hostname
    if (googlePlayURL.hostname !== "play.google.com") {
        return false;
    }

    // Check pathname
    if (googlePlayURL.pathname !== "/store/apps/details") {
        return false;
    }

    // Check for 'id' parameter
    return /^[A-Za-z0-9\.]+$/.test(googlePlayURL.searchParams.get('id'));
}


export const parseGooglePlayURL = (url:string):string => {
    const googlePlayURL = new URL(url);
    return googlePlayURL.searchParams.get('id');
}
