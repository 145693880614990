import {
  Paper,
  AppBar,
  Typography,
  Grid,
  TextField,
  Divider,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getUserSettingsAxios } from "../../utils/axios";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { FeedbackCreator } from "../Feedbacks/FeedbackCreator";

export const Settings = () => {
  const [userSettings, setUserSettings] = useState();

  const getUserSetting = async () => {
    const getUserSettingsRequest = await getUserSettingsAxios();
    if (getUserSettingsRequest.status === 200) {
      setUserSettings(getUserSettingsRequest.data);
    }
  };
  useEffect(() => {
    getUserSetting();
  }, []);

  // TO DO REVERSE THIS WHEN project will be added
  const lastProject = userSettings?.projects[userSettings.projects.length - 1];
  const googlePlayId = lastProject?.googlePlayAppId ?? "";

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        ></AppBar>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          To change data or make any request, please contact support or write to
          contact@chrystianshutz.com
        </Typography>
        {/* TO DO CHANGE */}
        {userSettings && (
          <>
            <Grid container marginBottom={2} marginLeft={6}>
              <Grid item md={4} marginBottom={1}>
                <TextField
                  label="First name"
                  value={userSettings.firstName}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />

              <Grid item md={4} marginBottom={1}>
                <TextField
                  label="Last name"
                  value={userSettings.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4} marginBottom={1}>
                <TextField
                  label="email"
                  value={userSettings.email}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4} marginBottom={1}>
                <TextField
                  label="Company name"
                  value={userSettings.companyName}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Divider light />
            {/* TO DO REVERSE THIS WHEN project will be added */}
            <Typography
              sx={{ my: 5, mx: 2 }}
              color="text.secondary"
              align="center"
            >
              Google Play settings (Google Play Store App Id)
            </Typography>
            <Grid container marginBottom={2} marginLeft={6}>
              <Grid item md={12} marginBottom={1}>
                <TextField
                  label="Google Play App Id:"
                  value={googlePlayId}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider light />
            <Typography
              sx={{ my: 5, mx: 2 }}
              color="text.secondary"
              align="center"
            >
              Plan settings
            </Typography>

            <Grid container marginBottom={2} marginLeft={6}>
              <Grid item md={12} marginBottom={1}>
                {/* <TextField
                  label="Api Key:"
                  value={userSettings.apiKey}
                  fullWidth
                /> */}
                <TextField
                  label="Api Key:"
                  value="Please request API access to use API key."
                  fullWidth
                />
              </Grid>

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Used API Requests"
                  value={userSettings.requestCount}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Available API Requests"
                  value={userSettings.allowedCount}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Remaining API Requests"
                  value={userSettings.leftRequests}
                  fullWidth
                />
              </Grid>

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Used Report/Questions Requests"
                  value={userSettings.reportCount}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Available Report/Questions Requests"
                  value={userSettings.reportAllowedCount}
                  fullWidth
                />
              </Grid>
              <Grid item md={1} />

              <Grid item md={3} marginBottom={1}>
                <TextField
                  label="Remaining Report/Questions Requests"
                  value={userSettings.leftReports}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Box sx={{ my: 5, mx: 2, marginLeft: 6, marginRight: 6 }}>
              To change your current plan, increase the number of texts for
              analysis, or adjust the maximum number of texts, reports, or
              questions, please specify how many texts/reports/questions you
              plan to submit. Fill out this form or send an email to
              contact@sentimentscanner.com. Alternatively, you can reach out to us on
              live chat or Slack. We will respond promptly.
              <FeedbackCreator
                header=""
                alertContent=""
                type="PLAN_CHANGE"
                textFieldLabel="Request plan change"
              />
            </Box>
          </>
        )}
      </Paper>
    </Overlay>
  );
};

export default Settings;
