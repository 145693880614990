import * as React from "react";
import { FeedbackCreator } from "./FeedbackCreator";

export const Help = () => (
  <FeedbackCreator
    header={
      <>
        If you need assistance, want to report a bug or request feature you can
        use the form below, write to us on 
        <a href="https://sentimentscannergroup.slack.com/join/shared_invite/zt-22k22rx6q-bUOtvtEACLdbdzuD0GNiFA#/shared-invite/email">
          Slack
        </a>, <a href="https://discord.com/invite/YuKksdwd">Discord</a> or email us
        on contact@sentimentscanner.com or use the live chat. Thank you for your
        patience, we will get back to you promptly.
      </>
    }
    alertContent="Application for help/bug was successfully send."
    type="help/bug"
    textFieldLabel="Application for help/bug/contact"
  />
);
