import { createBrowserRouter } from "react-router-dom";
import { AddNewText } from "./components/AddNewText/AddNewText";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import { Report } from "./components/Reports/Reports";
import Settings from "./components/Settings/Settings";
import TextTable from "./components/TextTable/TextTable";
import { ViewSingleText } from "./components/TextTable/ViewSingleText/ViewSingleText";
import Admin from "./components/Admin/Admin";
import { PlayMarket } from "./components/PlayMarket/PlayMarket";
import { Activation } from "./components/Activation/Activation";
import { ActivationNotice } from "./components/Activation/ActivationNotice";
import { Api } from "./components/Feedbacks/Api";
import { CustomFields } from "./components/Feedbacks/CustomFields";
import { Help } from "./components/Feedbacks/Help";
import Dashboard from "./components/Dashboard/Dashboard";
import { Onboarding } from "./components/Onboarding/Onboarding";
import { LoadingReport } from "./components/Onboarding/OnboardingLoading";
import RequestPasswordReset from "./components/PasswordReset/RequestPasswordReset";
import PasswordReset from "./components/PasswordReset/ResetPassword";
import { Unsubscribe } from "./components/Unsubscribe/Unsubscribe";

// TO DO fix to z tym / tego nie powinno byc tylko tu powiniem byc landing page a jak user ma id to dopiero wtedy redirect na dashboard
export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/analyzedTexts",
    element: <TextTable />,
  },
  {
    path: "/addText",
    element: <AddNewText />,
  },
  {
    path: "/text/:textId",
    element: <ViewSingleText />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/reports",
    element: <Report />,
  },
  {
    path: "/playmarket",
    element: <PlayMarket />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/activation/:id",
    element: <Activation />,
  },
  {
    path: "/activatePlease",
    element: <ActivationNotice />,
  },
  {
    path: "/apiRequest",
    element: <Api />,
  },
  {
    path: "/customFields",
    element: <CustomFields />,
  },
  {
    path: "/helpcenter",
    element: <Help />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/loadingReport",
    element: <LoadingReport />,
  },
  {
    path: "/requestPasswordReset",
    element: <RequestPasswordReset />,
  },
  {
    path: "/resetPassword/:token",
    element: <PasswordReset />,
  },
  {
    path: "/unsubscribe/:type/:emailBase64",
    element: <Unsubscribe />,
  },
  // {
  //     path: "/search",
  //     element: <Search/>
  // }
]);
