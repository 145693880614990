import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { activateAccountAxios, unsubscribeAxios } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Paper } from "@mui/material";
import { checkIfActivated } from "../../utils/axios";
import { mixPanelTrack } from "../../utils/mixPanel";
import Container from "@mui/material/Container";
import { Copyright } from "../CopyrightNotice/CopyrightNotice";
import CssBaseline from "@mui/material/CssBaseline";

export const Unsubscribe = () => {
  const { type, emailBase64 } = useParams();
  const email = atob(emailBase64);

  const handleUnsubscribe = async () => {
    try {
      mixPanelTrack('unsubscribe')
      await unsubscribeAxios(type,email);
   
    } catch (error) {
      console.log("unsubscribe error:", error);
    }
  };

  useEffect(() => {
    handleUnsubscribe()
  }, [])

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper marginLeft={8} marginRight={8} padding="100px">
        You have successfully unsubscribed from {type}
      </Paper>
    </Box>
    <Copyright sx={{ mt: 8, mb: 4 }} />

    </Container>

  );
};
