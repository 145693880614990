import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
  } from "@mui/material";
  import React, { MouseEventHandler, useEffect, useState } from "react";
  import { mixPanelTrack } from "../../utils/mixPanel";
  
  interface ConfirmationModalProps {
    isOpen: boolean;
    callbackConfirm: MouseEventHandler<HTMLButtonElement>;
    callbackCancel: MouseEventHandler<HTMLButtonElement>;
  }
  
  const ConfirmationModalChangeProject: React.FC<ConfirmationModalProps> = ({
    isOpen,
    callbackConfirm,
    callbackCancel,
  }) => {
    
    useEffect(() => {
      mixPanelTrack('Change Project')
    }, []);
  
    return (
      <Dialog open={isOpen} keepMounted onClose={callbackCancel}>
        <DialogTitle>Are you sure you want to change this App?</DialogTitle>
        <DialogContent>This will delete your all current app data (report/questions/charts).</DialogContent>
        <DialogActions>
          <Button onClick={callbackCancel}>Cancel</Button>
          <Button onClick={callbackConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    );
  };
  export default ConfirmationModalChangeProject;
  