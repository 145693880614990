import * as React from "react";
import Overlay from "../DashboardOverlayContainer/Overlay";
import { getAadminRoute } from "../../utils/axios";

const Admin = () => {
  const [log, setLog] = React.useState({});

  const getLog = async () => {
    const logRequest = await getAadminRoute();
    setLog(logRequest.data);
    window.admin = logRequest.data;
  };
  React.useEffect(() => {
    getLog();
  }, []);

  return (
    <Overlay>
      Admin route
      <div>Data is in window.admin</div>
      <hr />

      <div
        style={{ backgroundColor: "#d3d3d3", width: "250px", float: "left" }}
      >
        uniqueCategoryCounts:{" "}
        {log?.uniqueCategoryCounts?.length > 1 &&
          log.uniqueCategoryCounts.map((category) => {
            return (
              <div>
                {category.category} : {category._count.category}
              </div>
            );
          })}
      </div>
      <div>
        <b>Failed:</b>
        <div>failedReports: {log?.failedReports?.length}</div>
        <div>failedTexts: {log?.failedTexts?.length}</div>
        <div>failedGooglePlay: {log?.failedGooglePlay?.length}</div>

        <hr />
        <b>TEXTS</b>
        <div>textToAnalyzeCount: {log?.textToAnalyzeCount}</div>
        <div>processingTexts: {log?.processingTexts?.length}</div>

        <hr />

        <div>userCount: {log?.userCount}</div>

        <div>reportCount: {log?.reportCount}</div>
        <div>googlePlayCount: {log?.googlePlayCount}</div>
      </div>
    </Overlay>
  );
};

export default Admin;
