import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Overlay from "../DashboardOverlayContainer/Overlay";
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import { checkIfActivated, checkIfReportIsReadyAxios } from "../../utils/axios";
import { useNavigate, useLocation } from "react-router-dom";

export const LoadingReport = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [reportHtml, setReportHtml] = useState();
  const [isEmpty, setIsEmpty] = useState(false);

  const checkIfReportIsReady = async () => {
    const reportCheckRequest = await checkIfReportIsReadyAxios(state.id);
    const isReportReady = reportCheckRequest.data.report.analyzed;
    const reportLength = reportCheckRequest?.data?.report?.contentLength;
    const reportProcessing = reportCheckRequest?.data?.report?.processing;

    const isReportProcessedEmpty =
      reportProcessing == false && reportLength == 0;
    console.log(isReportReady, reportLength == 0);
    if (isReportProcessedEmpty) {
      setIsEmpty(true);
    }

    if (isReportReady || isReportProcessedEmpty) {
      setReportHtml(reportCheckRequest.data.report.emailHtml);
    } else {
      checkIfReportIsReadyTimeout();
    }

    // if (!isReportReady) {
    //   // console.log('//', !isReportReady || reportLength !== 0,!isReportReady,  reportLength !== 0)
    //   checkIfReportIsReadyTimeout();
    // } else {
    //   console.log("!!!!", reportCheckRequest.data.report.emailHtml);
    //   setReportHtml(reportCheckRequest.data.report.emailHtml);
    // }
  };

  const checkIfReportIsReadyTimeout = async () => {
    await new Promise((res) => setTimeout(res, 5000));
    await checkIfReportIsReady();
  };

  useEffect(() => {
    checkIfReportIsReadyTimeout();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: reportHtml ? "initial" : "100vh",
      }}
    >
      <Paper marginLeft={8} marginRight={8} padding="100px">
        {reportHtml ? (
          <Box
            sx={{ marginRight: "10vh", marginLeft: "10vh", marginTop: "10vh" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: reportHtml.replace(
                  "You can view it in the site or in the email below",
                  ""
                ),
              }}
            />

            <Box sx={{ display: "block", textAlign: "center", margin: 2 }}>
              {isEmpty ? (
                <Button
                  variant="contained"
                  onClick={() => navigate("/onboarding")}
                >
                  Try again
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => navigate("/dashboard")}
                >
                  View more on Dashboard
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CircularProgress size={100} />
            </Grid>
            <Grid item xs={12}>
              <Typography padding={10}>
                Please wait. As we are getting data from Google Play. Please
                wait as it can take up to few minutes.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};
