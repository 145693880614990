import { CompactTable } from "@table-library/react-table-library/compact";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/material-ui";
import { useRowSelect } from "@table-library/react-table-library/select";
import { useState } from "react";

const Table = ({ tableData, navigate, setSelected }) => {
  const materialTheme = getTheme({
    horizontalSpacing: 0.5,
    verticalSpacing: 0.2,
    striped: true,
  });
  const [expandedIds, setExpandedIds] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([''])
  //       --data-table-library_grid-template-columns:  3.5% 1% 4.5% 20% 20% 20% 20% minmax(150px, 1fr);

  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns:  3% 3% 20% 30% 20% 20% 20% minmax(150px, 1fr);
    `, // modify here to fix overflow if you add new column
  };
  // const customTheme = {
  //   // TO DO naprawic przesuniecie tabelki, ma malej rodzilczosci ok, na duzej odpierdala
  //   // (to drugie 0px to spacing)        checkbox spacing
  //   // Table: `

  //   //     `, // modify here to fix overflow if you add new column


  // };

  const theme = useTheme([materialTheme, customTheme]);

  const COLUMNS = [
    {
      label: "",
      renderCell: (item) => (
        <IconButton onClick={() => navigate(`/text/${item.id}`)}>
          <ArrowForwardIcon />
        </IconButton>
      ),
      select: {
        renderHeaderCellSelect: () => (
          <Checkbox
            size="small"
            id="selectAllTexts"
            checked={select.state.all}
            indeterminate={!select.state.all && !select.state.none}
            onChange={select.fns.onToggleAll}
          />
        ),
        // renderCellSelect: (item) => (
        //   <Checkbox
        //     size="small"
        //     checked={select.state.ids.includes(item.id)}
        //     onChange={() => select.fns.onToggleById(item.id)}
        //   />
        // ),
      },
      resize: true
    },

    { label: "Content", renderCell: (item) => item.content, resize: true },
    {
      label: "Positive Sentiments",
      renderCell: (item) => (
        <div>
          {item.positiveSentiments.map((value) => (
            <p>{value.text}</p>
          ))}
        </div>
      ), resize: true 
    },
    {
      label: "Negative Sentiments",
      renderCell: (item) => (
        <div>
          {item.negativeSentiments.map((value) => (
            <p>{value.text}</p>
          ))}
        </div>
      ), resize: true 
    },
    {
      label: "Actionable Actions",
      renderCell: (item) => (
        <div>
          {item.actionableActions.map((value) => (
            <p>{value.text}</p>
          ))}
        </div>
      ), resize: true 
    },
    { label: "Summary", renderCell: (item) => item.summary , resize: true },
    // { label: 'Processing', renderCell: (item) => item.processing },
    // { label: 'Failed To Process', renderCell: (item) => item.failedToProcess },
  ];

  const onSelectChange = (action, state) => {
    setSelected(state.ids);
  };
  const select = useRowSelect(
    { nodes: tableData ?? [] },
    {
      onChange: onSelectChange,
    }
  );

  const handleExpanded = ({ id }) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((idx) => idx !== id));
    } else {
      setExpandedIds(expandedIds.concat(id));
    }
  };
  const ROW_PROPS = {
    onClick: handleExpanded,
  };

  // const ROW_OPTIONS = { // ROZWALA TABELKE
  //   renderAfterRow: (item) => (
  //     <Collapse className="animate" in={expandedIds.includes(item.id)}>
  //       <tr style={{ flex: "1", display: "flex" }}>
  //         <td style={{ flex: "1" }}>
  //           {/* <ul>getAllUsersTextsAxios</ul> */}
  //         </td>
  //       </tr>
  //     </Collapse>
  //   ),
  // };

  if(tableData?.length === 0){
    return(
      <Box>
        
      </Box>
    )
  }
  return (
    <Box sx={{'& .td div': {whiteSpace: 'inherit !important'}}}>
      {/* <Box height={600} overflow="auto" display="block" id="tabelekaWrapper"> */}
        <CompactTable
          columns={COLUMNS}
          data={{ nodes: tableData ?? [] }}
          theme={theme}
          layout={{ custom:true,horizontalScroll: true, fixedHeader: true }}
          select={select}
          rowProps={ROW_PROPS}
          // rowOptions={ROW_OPTIONS}
          // selectableRowsComponent={Checkbox}
        />
      {/* </Box> */}
    </Box>
  );
};

export default Table;
