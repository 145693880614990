import {
  Paper,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  IconButton,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import ISO6391 from "iso-639-1";
import { iso31661 } from "iso-3166";
import HelpIcon from "@mui/icons-material/Help";
import {
  parseGooglePlayURL,
  validateGooglePlayURL,
} from "../PlayMarket/validateGooglePlay";
import DynamicSearch from "./DynamicAppSearch";

export const SetupGooglePlay = ({
  setGooglePlayForm,
  googlePlayForm,
  setUrl,
  url,
}) => {
  const [validationError, setValidationError] = React.useState(false);

  React.useEffect(() => {
    const validationSuccess = validateGooglePlayURL(url);
    if (validationSuccess === true) {
      const id = parseGooglePlayURL(url);
      setValidationError(false);
      setGooglePlayForm({
        ...googlePlayForm,
        googlePlayAppId: id,
      });
    } else {
      setValidationError(true);
    }
  }, [url]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleCountryChange = (e, newValue) => {
    const countryCode = iso31661.find((item) => item?.name === newValue)?.alpha2;
    // setCountryCode(countryCode.alpha2);
    setGooglePlayForm({
      ...googlePlayForm,
      countryCode,
      countryName: newValue,
    });
  };

  const handleLanguageChange = (e, newValue) => {
    const languageCode = ISO6391.getCode(newValue);
    setGooglePlayForm({
      ...googlePlayForm,
      languageCode,
      languageName: newValue,
    });
  };

  const countryCodes = iso31661
    .map((country) => country.name)
    .filter((name) => name !== "Russia");

  return (
    <Paper sx={{padding: 3}}>
      {/* <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        The retrieval of comments from Google Play can be performed on a weekly,
        bi-weekly, or monthly basis, and they will be analyzed and incorporated
        into the texts table. You can also request retrieval instantly. You will
        receive notification email after comments have been analyzed. The amount
        of retrieved and processed texts depend on you current plan. You will be
        billed only for analyzed comments. Example Google App URL:
        https://play.google.com/store/apps/details?id=com.google.android.gm
      </Paper> */}

      <Grid container xs={12}>
        <Grid item xs={10} md={5}>
          <Autocomplete
            disablePortal
            id="country"
            value={googlePlayForm.countryName}
            onChange={handleCountryChange}
            options={countryCodes}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        </Grid>
        <Grid item md={1} >
          <Tooltip title="This is country where you want search your app, as some apps are available only in selected countries">
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={10} md={5} >
          <Autocomplete
            disablePortal
            id="language"
            value={googlePlayForm.languageName}
            onChange={handleLanguageChange}
            options={ISO6391.getAllNames()}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="Language" />}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="This is languages of the app are you searching as some apps maybe unavailable in some languages">
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Paper
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container xs={12}>
        <Grid item xs={12} md={5}>

          <DynamicSearch
            country={googlePlayForm.countryCode}
            lang={googlePlayForm.languageCode}
            setGooglePlayForm={setGooglePlayForm}
            googlePlayForm={googlePlayForm}
          />
          </Grid>

          <Grid item xs={10} marginTop={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="#d3d3d3">
                  Can't find by the name of the app above? Try to pase google
                  play URL
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={url}
                  onChange={handleUrlChange}
                  label="Google Play URL"
                  helper="Incorrect Google Play URL"
                  id="googlePlayInput"
                  fullWidth
                  sx={{ marginRight: 1 }}
                  error={url && validationError}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Paste here the url from google play. Like https://play.google.com/store/apps/details?id=com.google.android.gm&hl=pl&gl=US">
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
      {/* <Paper sx={{ marginTop: 2 }}>
            Curently your plan alows you to set up bi-weekly and monthly reports
            and retrieve 100 comments. / Tutaj 3 rozne opcje. \ Tutaj pokazane ile
            aktualnie masz tekstow w bazie danych: currentTextCount i ile moze
            obrac. If you wish to increase the amount of the aviable texts to
            analyze consider deleting old texts or upgrade your plan.
          </Paper> */}
    </Paper>
  );
};
