import axios from "axios";
import { getApiToken, getJwtToken } from "./JWT_API_TOKENS";

const redirectToLoginOnAuthError = (error) => {
  // console.log(error.response.status)
  if (error?.response?.status === 403) {
    window.location.href = "/login";
  }
};
// const server = "http://localhost:80"; // as we have proxy in package.json we don't need anything

// const server = process.env.REACT_APP_SERVER_URL
const server = ""; // as we have proxy in package.json we don't need anything
// const server = process.env.REACT_APP_SERVER || "http://staging.redmoso.com";

const config = {
  headers: {
    Authorization: "Bearer " + getJwtToken(),
  },
};

//// ADMIN

export const getAadminRoute = () => {
  return axios
    .get(server + "/log", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

//// ADMIN

export const registerAxios = async (formData: any) => {
  return axios
    .post(server + "/register", formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const loginAxios = async (formData: any) => {
  return axios
    .post(server + "/login", formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const activateAccountAxios = async (id: any) => {
  return axios
    .post(server + "/activation", { id })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const checkIfActivated = async () => {
  return axios
    .get(server + "/isUserActivated", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const checkIfReportIsReadyAxios = async (id) => {
  return axios
    .get(server + `/getGooglePlayReport?id=${id}`, {
      headers: {
        "X-API-Key": getApiToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const searchForAppAxios = async (search, country, lang) => {
  return axios
    .post(
      server + "/searchGooglePlay",
      {
        search,
        country,
        lang,
      },
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};

// ADD PROJECT

export const getAllUsersTextsAxios = () => {
  return axios
    .get(server + "/getUserTexts", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const getDashboardDataAxios = () => {
  return axios
    .get(server + "/getDashboardData", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};
// ADD PROJECT

export const addNewTextAxios = async (formData: any) => {
  return axios
    .post(server + "/analyzeText", formData, {
      headers: {
        "X-API-Key": getApiToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};
// ADD PROJECT

export const getTextExcelAxios = () => {
  return axios
    .get(server + "/getUserTextsToExcel", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
      responseType: "blob",
    })
    .then(function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sentiments.xlsx"); // lub dowolna inna nazwa pliku
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const getSingleText = (id: number) => {
  return axios
    .get(server + `/getSingleUserText?id=${id}`, {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      redirectToLoginOnAuthError(error);
      return error;
    });
};
// ADD PROJECT

export const getSearchUsersTextsAxios = (search) => {
  return axios
    .get(server + `/search?searchText=${search}`, {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const getUserSettingsAxios = () => {
  return axios
    .get(server + "/userSettings", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

// ADD PROJECT

export const postReportAxios = (date) => {
  return axios
    .post(
      server + "/report",
      {},
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

// ADD PROJECT

export const getReportListAxios = () => {
  return axios
    .get(server + "/reportList", {
      headers: {
        "X-API-Key": getApiToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};
// ADD PROJECT

export const postReportCustomQuestionAxios = (customQuestion: string) => {
  return axios
    .post(
      server + "/report",
      {
        customQuestion,
      },
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const deleteTextsAxios = (ids) => {
  return axios
    .delete(server + `/userTexts?id=[${ids}]`, {
      headers: {
        "X-API-Key": getApiToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const getProjects = () => {
  return axios
    .get(server + "/projects", {
      headers: {
        "X-API-Key": getApiToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};
// ADD PROJECT
interface GooglePlayRequest {
  googlePlayAppId: string;
  commentsLength: number;
  scrapperFrequency: string;
  createReportAfter: boolean;
  languageCode: string;
  countryCode: string;
  projectId: number;
}

export const postRequestGooglePlay = ({
  googlePlayAppId,
  commentsLength,
  scrapperFrequency,
  createReportAfter,
  languageCode,
  countryCode,
  projectId,
}: GooglePlayRequest) => {
  return axios
    .post(
      server + "/requestGooglePlay",
      {
        googlePlayAppId,
        commentsLength,
        scrapperFrequency,
        createReportAfter,
        languageCode,
        countryCode,
        projectId,
      },
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const addNewFeedbackAxios = async (formData: any) => {
  return axios
    .post(server + "/developmentRequests", formData, {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      // redirectToLoginOnAuthError(error);
      return error;
    });
};

interface addNewProjectRequest {
  name: string;
  isGooglePlay: boolean;
  googlePlayAppId?: string;
  googlePlayName?: string;
}
export const addNewProjectOnboardingAxios = async ({
  name,
  isGooglePlay,
  googlePlayAppId,
  googlePlayName,
}: addNewProjectRequest) => {
  return axios
    .post(
      server + "/projects",
      {
        name,
        isGooglePlay,
        googlePlayAppId,
        googlePlayName,
      },
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      // redirectToLoginOnAuthError(error);
      return error;
    });
};

export const checkIfLoggedIn = async () => {
  return axios
    .get(server + "/isUserActive", {
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      redirectToLoginOnAuthError(error);
      return error;
    });
};

export const requestResetPasswordAxios = async (email: string) => {
  return axios
    .post(server + "/resetPassword", email)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const resetPasswordAxios = async ({ token, password }) => {
  return axios
    .post(server + "/updatePassword", {
      token,
      password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const deleteAllAxios = async () => {
  return axios
    .post(
      server + "/deleteAll",
      {},
      {
        headers: {
          "X-API-Key": getApiToken(),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const unsubscribeAxios = async (type,email) => {
  return axios
    .get(server + `/unsubscribe?type=${type}&email=${email}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
