import {
  Paper,
  AppBar,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Fade,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addNewTextAxios, getUserSettingsAxios } from "../../utils/axios";
import AlertNotification from "../AlertComponents/Alert";
import Overlay from "../DashboardOverlayContainer/Overlay";

export const AddNewText = () => {
  const [content, setContent] = useState("");
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [successTextId, setSuccessTextId] = useState(0);
  const [availableRequests, setAvailableRequests] = useState(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const addTextRequest = await addNewTextAxios({
      text: data.get("content"),
    });
    if (addTextRequest.status === 200) {
      // green popup with id
      setContent("");
      setSuccessVisibility(true);
      setSuccessTextId(addTextRequest.data.id);
      setAvailableRequests(availableRequests - 1);
      setAlertVisibility(false);
    } else {
      // red popup and error
      setAlertVisibility(true);
      const statusCode = addTextRequest?.response?.status;
      if (statusCode === 413 || statusCode === 406) {
        // entity too large
        setErrorMessage(
          "Text is too large to process, consider splitting to smaller chunks."
        );
      }
      if (statusCode === 429) {
        setErrorMessage("No more available requests for adding text.");
      } else {
        setErrorMessage("Error when try to process this text.");
      }
    }
  };

  // requst count
  // TO DO - brac tylko raz, a potem z response addNewTextAxios
  const getUserSetting = async () => {
    const getUserSettingsRequest = await getUserSettingsAxios();
    if (getUserSettingsRequest.status === 200) {
      setAvailableRequests(getUserSettingsRequest.data.leftRequests);
    }
  };
  useEffect(() => {
    getUserSetting();
  }, []);

  return (
    <Overlay>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        ></AppBar>

        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          {/* TO DO przerobic to na snackbar, gdzie bedzie lista dodanych tekstów, a nie ze tylko 1 sie wyswietla */}
          <AlertNotification
            type="success"
            alertVisibility={successVisibility}
            setAlertVisibility={setSuccessVisibility}
            title="success"
            content={
              <>
                <Link to={`/text/${successTextId}`}>Text</Link> was successfully
                added for analyzing.
              </>
            }
          />
          <AlertNotification
            type="error"
            alertVisibility={alertVisibility}
            setAlertVisibility={setAlertVisibility}
            title="Error"
            content={errorMessage}
          />

          <Typography>Available API Requests: {availableRequests}</Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="content"
                required
                fullWidth
                id="content"
                label="Text content"
                autoFocus
                multiline
                rows={4}
                maxRows={16}
                onChange={handleOnChange}
                value={content}
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add text
            </Button>
          </Box>
        </Typography>
      </Paper>
    </Overlay>
  );
};
