import {
  Paper,
  AppBar,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Alert,
  AlertTitle,
  Fade,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

interface AlertNotificationProps {
  alertVisibility: boolean;
  setAlertVisibility: (visibility: boolean) => void;
  title: string;
  content: string | React.ReactNode;
  type: "error" | "info" | "success" | "warning";
}

const AlertNotification: React.FC<AlertNotificationProps> = ({
  alertVisibility,
  setAlertVisibility,
  title,
  content,
  type,
}) => {
  if (alertVisibility) {
    return (
      <Fade
        in={alertVisibility}
        timeout={{ enter: 1000, exit: 10000 }}
        addEndListener={() => {
          setTimeout(() => {
            setAlertVisibility(false);
          }, 10000);
        }}
      >
        <Alert
          severity={type}
          variant="standard"
          className="alert"
          sx={{
            textAlign: "center",
            display: "inline-block",
            marginTop: 1,
            marginBottom: 1,
            "MuiAlert-icon": {
              display: "block",
              textAlign: "center",
              margin: "auto",
              padding: 0,
            },
          }}
        >
          <AlertTitle>{title} </AlertTitle>
          {content}
        </Alert>
      </Fade>
    );
  }
  return null;
};

export default AlertNotification;
